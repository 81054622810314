<template>
  <div>
    <div>
      <el-select style="margin-left: 5px;margin-bottom: 20px" v-model="oper" placeholder="请选择标段" @change="getfileurl" size="small">
        <el-option
            v-for="item in bdsts"
            :key="item.bdst"
            :label="item.bdstname"
            :value="item.bdst"
        >
        </el-option>
      </el-select>
    </div>
    <div>
      <vxe-table
          :align="allAlign"
          show-overflow
          auto-resize
          height="350"
          :data="noticeData">
        <vxe-column type="seq" title="序号" width="60"></vxe-column>
        <vxe-column field="filename" width="900" title="文件名称"></vxe-column>
        <vxe-column field="bdst" width="200" title="对应工区"></vxe-column>
        <vxe-column title="操作" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="selfile(row)">查看</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!--    分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
    <!--    下载详情弹窗-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          :title="title"
          width="80%"
      >
        <vxe-table
            :align="allAlign"
            show-overflow
            auto-resize
            height="350"
            :data="noticeData1">
          <vxe-column type="seq" title="序号" width="60"></vxe-column>
          <vxe-column field="bdstname" width="200" title="已查看工区"></vxe-column>
          <vxe-column field="username" width="200" title="查看人员"></vxe-column>
          <vxe-column field="a" width="200" title="是否领取纸质">
            <template #default="{ row }">
              <el-tag v-if="row.type === 1" type="success">已领取</el-tag>
              <el-tag v-if="row.type === 0" type="danger">未领取</el-tag>
            </template>
          </vxe-column>
          <vxe-column title="操作" show-overflow>
            <template #default="{ row }">
              <vxe-button v-if="row.bdst.slice(0,1) === (this.bdst.slice(0,1)) && row.bdst.indexOf(this.bdst) !== -1" size="mini" status="primary" @click="getfile(row)">领取</vxe-button>
            </template>
          </vxe-column>
        </vxe-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { ElMessage } from 'element-plus';
export default {
  name: "notice",
  data() {
    return {
      oper: '',
      bdsts: [],
      noticeData: [],
      allAlign: 'center',
      dialogYlFile: false,
      fileurl: '',
      pagenum: 1,
      totals: 0,
      pd: true,
      noticeData1: [],
      title: '',
      dialogVisible: false,
      bdst: ''
    }
  },
  created() {
    this.axios.get('/other/exzx', (response) =>  {
      this.bdsts = response.obj
    });
    this.axios.get('/fileurl/selurl', (response) => {
      this.totals = response.obj.pages * 10;
      this.noticeData = response.obj.records
    }, {
      filetype: 2,
      pageCount: 1
    })
  },
  computed: {
    ...mapGetters(['GET_USER']),
    ...mapGetters(['GET_BDST'])
  },
  methods: {
    selfile(row) {
      this.axios.get('/fileurl/selbyid', (response) => {
        this.dialogVisible = true;
        this.title = response.obj.bdstname + '---' + row.filename;
        this.bdst = response.obj.bdst;
        this.noticeData1 = response.obj.filedownList;
      },{
        id: row.id
      })
    },
    getfileurl(){
      this.pd = false;
      this.axios.get('/fileurl/selurlbybdst', (response) => {
        this.totals = response.obj.pages * 10;
        this.noticeData = response.obj.records
      }, {
        filetype: 2,
        bdst: this.oper,
        pageCount: 1
      })
    },
    getfile(row){
      this.axios.get('/fileurl/uptype', (response) => {
        if (response.obj){
          row.type = 1;
        }
      },{
        id: row.id
      })
    }
  },
  watch: {
    pagenum(){
      if (this.pd){
        this.axios.get('/fileurl/selurl', (response) => {
          this.totals = response.obj.pages * 10;
          this.noticeData = response.obj.records
        }, {
          filetype: 2,
          pageCount: this.pagenum
        })
      }else {
        this.axios.get('/fileurl/selurlbybdst', (response) => {
          this.totals = response.obj.pages * 10;
          this.noticeData = response.obj.records
        }, {
          filetype: 2,
          bdst: this.oper,
          pageCount: this.pagenum
        })
      }
    }
  }
}
</script>

<style scoped>

</style>